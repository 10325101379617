import React, { useState, useEffect, memo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Button, Dropdown, Avatar, Badge } from "flowbite-react";
import { motion } from "framer-motion";
import { HiMenuAlt3, HiX, HiHome, HiInbox, HiShoppingBag, HiUser, HiCog, HiLogout, HiLightningBolt, HiPencilAlt, HiClock } from "react-icons/hi";
import useCookie from '../../context/useCookie';
const Header = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const userInfo = useCookie('user');
  const user = userInfo ? JSON.parse(userInfo) : null;
  const { name, email, picture } = user || {};
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  useEffect(() => {
    if (location.pathname === '/' && location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  const navItems = [
    { name: 'AI Notes', href: '/#ai-notes' },
    { name: 'Brainstorming Tools', href: '/#brainstorming-tools' },
    { name: 'Result Export', href: '/#result-export' },
    { name: 'Features', href: '/#features' },
    { name: 'Safety', href: '/#safety' },
    { name: 'AI Models', href: '/#ai' },
    { name: 'Blog', href: '/blog', icon: HiPencilAlt },
  ];
  const dashboardItems = [
    { name: 'Dashboard', href: '/dashboard', icon: HiHome },
    { name: 'Workflows', href: '/dashboard/workflows', icon: HiInbox },
    { name: 'My History', href: '/dashboard/usage-history', icon: HiClock },
    { name: 'Profile', href: '/dashboard/profile', icon: HiUser },
    { name: 'Settings', href: '/dashboard/settings', icon: HiCog },
  ];
  const buttonsItems = [
    { name: 'Dashboard', href: '/dashboard', icon: HiHome },
    { name: 'Workflows', href: '/dashboard/workflows', icon: HiInbox },
    { name: 'My History', href: '/dashboard/usage-history', icon: HiClock },
  ];
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };
  const NavLink = ({ href, children, onClick, isMobile }) => {
    const handleClick = (e) => {
      e.preventDefault();
      if (href.startsWith('/#')) {
        if (location.pathname !== '/') {
          navigate(href);
        } else {
          const element = document.querySelector(href.substring(1));
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      } else {
        navigate(href);
      }
      closeMobileMenu();
    };
    const linkColor = isMobile
      ? 'text-white hover:text-secondary'
      : isScrolled
        ? 'text-white hover:text-secondary'
        : 'text-primary hover:text-secondary';
    return (
      <a
        href={href}
        onClick={onClick || handleClick}
        className={`${linkColor} px-3 py-2 rounded-md text-sm font-medium transition-colors duration-300 flex items-center`}
      >
        {children}
      </a>
    );
  };
  return (
    <Navbar
      fluid
      rounded
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled
          ? 'bg-primary bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-lg'
          : email
            ? 'bg-white'
            : 'bg-transparent'
      }`}
      style={{ zIndex: 9999 }}
    >
      <div className="w-full px-4 md:px-8 flex justify-between items-center">
        <Navbar.Brand href="/" className="flex items-center">
          <div className="rounded-xl overflow-hidden">
            <motion.img
              src="/images/logo-dots-no-back.png"
              className={`h-8 sm:h-10 ${
                isScrolled 
                  ? 'bg-transparent' 
                  : email 
                    ? 'bg-primary' 
                    : 'bg-white bg-opacity-20'
              } p-1 rounded-xl`}
              alt="LLMazing UseCases Logo"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            />
          </div>
          <Badge color="warning" size="sm" className="ml-2 animate-pulse">
            BETA
          </Badge>
        </Navbar.Brand>
        <div className="hidden md:flex items-center space-x-4">
          {email ? (
            <>
              <div className="flex space-x-2">
                {buttonsItems.map((item, index) => (
                  <NavLink
                    key={index}
                    href={item.href}
                    onClick={() => {
                      navigate(item.href);
                      closeMobileMenu();
                    }}
                  >
                    <item.icon className="mr-1" /> {item.name}
                  </NavLink>
                ))}
                <NavLink href="/blog">
                  <HiPencilAlt className="mr-1" /> Blog
                </NavLink>
              </div>
              <Badge className="mr-2 bg-gradient-to-r from-secondary to-accent text-white font-bold py-2 px-4 rounded-lg shadow-lg">
                Free Beta Access
              </Badge>
              <Dropdown
                arrowIcon={false}
                inline
                label={
                  <Avatar rounded size="sm" img={picture} alt={name} />
                }
              >
                <Dropdown.Header>
                  <span className="block text-sm font-medium text-gray-900">{name}</span>
                  <span className="block truncate text-sm text-gray-500">{email}</span>
                </Dropdown.Header>
                {dashboardItems.map((item) => (
                  <Dropdown.Item 
                    key={item.name} 
                    icon={item.icon} 
                    onClick={() => {
                      navigate(item.href);
                      closeMobileMenu();
                    }}
                  >
                    {item.name}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item 
                  icon={HiLogout} 
                  onClick={() => {
                    navigate('/logout');
                    closeMobileMenu();
                  }}
                >
                  Sign out
                </Dropdown.Item>
              </Dropdown>
            </>
          ) : (
            <>
              {navItems.map((item) => (
                <NavLink key={item.name} href={item.href}>
                  {item.icon && <item.icon className="mr-1" />} {item.name}
                </NavLink>
              ))}
              <Link to="/register" onClick={closeMobileMenu}>
                <Button className="px-6 py-3 text-lg font-semibold bg-gradient-to-r from-secondary to-accent text-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                  <HiLightningBolt className="mr-2" />
                  Sign Up
                </Button>
              </Link>
            </>
          )}
        </div>
        <div className="md:hidden">
          <Button
            color={isScrolled || email ? "light" : "dark"}
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <HiX className="h-6 w-6" /> : <HiMenuAlt3 className="h-6 w-6" />}
          </Button>
        </div>
      </div>
      {isMobileMenuOpen && (
        <motion.div
          className="md:hidden mt-2 bg-primary bg-opacity-95 backdrop-filter backdrop-blur-lg rounded-b-lg"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="px-2 pt-2 pb-3 space-y-1">
            {email ? (
              <>
                {dashboardItems.map((item) => (
                  <NavLink key={item.name} href={item.href} isMobile={true}>
                    <item.icon className="mr-2" /> {item.name}
                  </NavLink>
                ))}
                <NavLink href="/blog" isMobile={true}>
                  <HiPencilAlt className="mr-2" /> Blog
                </NavLink>
                {email === 'arsen.apostolov@gmail.com' && (
                  <NavLink
                    href="/blog/admin"
                    onClick={() => {
                      navigate('/blog/admin');
                      closeMobileMenu();
                    }}
                    isMobile={true}
                  >
                    <HiPencilAlt className="mr-2" /> Admin
                  </NavLink>
                )}
                <div className="border-t border-secondary pt-4 pb-3">
                  <div className="flex items-center px-5">
                    <Avatar rounded size="md" img={picture} alt={name} />
                    <div className="ml-3">
                      <div className="text-base font-medium text-white">{name}</div>
                      <div className="text-sm font-medium text-secondary">{email}</div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    <Badge className="mb-2 bg-gradient-to-r from-secondary to-accent text-white font-bold py-2 px-4 rounded-lg shadow-lg">
                      Free Beta Access
                    </Badge>
                    <NavLink href="/logout" isMobile={true}>
                      <HiLogout className="mr-2" /> Sign out
                    </NavLink>
                  </div>
                </div>
              </>
            ) : (
              <>
                {navItems.map((item) => (
                  <NavLink key={item.name} href={item.href} isMobile={true}>
                    {item.icon && <item.icon className="mr-2" />} {item.name}
                  </NavLink>
                ))}
                <div className="pt-2 pb-3">
                  <Link to="/register" onClick={closeMobileMenu}>
                    <Button className="w-full px-6 py-3 text-lg font-semibold bg-gradient-to-r from-secondary to-accent text-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300">
                      <HiLightningBolt className="mr-2" />
                      Sign Up
                    </Button>
                  </Link>
                </div>
              </>
            )}
          </div>
        </motion.div>
      )}
    </Navbar>
  );
});
export default Header;