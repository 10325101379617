import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Flowbite, Toast } from 'flowbite-react';
import { HiCheck } from 'react-icons/hi';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import FeedbackButton from './components/common/FeedbackButton';
import { customTheme } from './customTheme';

const MainContent = lazy(() => import('./components/MainContent'));
const Dashboard = lazy(() => import('./components/dashboard/Dashboard'));
const RegisterPage = lazy(() => import('./components/auth/RegisterPage'));
const Logout = lazy(() => import('./components/auth/Logout'));
const ToolTemplate = lazy(() => import('./components/dashboard/tools/toolTemplate'));
const TermsAndConditions = lazy(() => import('./components/common/TermsAndConditions'));
const DataPrivacy = lazy(() => import('./components/common/DataPrivacy'));
const Workflows = lazy(() => import('./components/dashboard/Workflows'));
const Marketplace = lazy(() => import('./components/dashboard/Marketplace'));
const Profile = lazy(() => import('./components/dashboard/Profile'));
const Settings = lazy(() => import('./components/dashboard/Settings'));
const Overview = lazy(() => import('./components/dashboard/Overview'));
const AboutUsPage = lazy(() => import('./components/AboutUsPage'));
const BlogRoutes = lazy(() => import('./components/blog/BlogRoutes'));
const UsageHistoryDashboard = lazy(() => import('./components/dashboard/UsageHistoryDashboard'));
const NotFoundPage = lazy(() => import('./components/NotFoundPage'));
const ToolsProvider = lazy(() => import('./context/ToolsContext').then(module => ({ default: module.ToolsProvider })));
const WorkflowsProvider = lazy(() => import('./context/WorkflowsContext').then(module => ({ default: module.WorkflowsProvider })));
const SessionLogsProvider = lazy(() => import('./context/SessionLogsContext').then(module => ({ default: module.SessionLogsProvider })));
const BlogProvider = lazy(() => import('./context/BlogContext').then(module => ({ default: module.BlogProvider })));
const ContextsProvider = lazy(() => import('./context/ContextsContext').then(module => ({ default: module.ContextsProvider })));

const LoadingOverlay = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex flex-col items-center justify-center text-white">
    <div className="text-2xl mb-4">Loading l'Amazing Content...</div>
    <div className="relative w-32 h-32 drop-shadow-2xl">
      <svg
        className="animate-spin w-full h-full"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  </div>
);

const FeedbackWrapper = ({ children }) => {
  const location = useLocation();
  const showFeedback = location.pathname !== '/';
  return (
    <>
      {children}
      {showFeedback && <FeedbackButton />}
    </>
  );
};

const CookieNotification = () => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const hasSeenCookieNotice = localStorage.getItem('hasSeenCookieNotice');
    if (!hasSeenCookieNotice) {
      setShowToast(true);
      localStorage.setItem('hasSeenCookieNotice', 'true');
    }
  }, []);

  if (!showToast) return null;

  return (
    <div className="fixed bottom-5 right-5 z-50">
      <Toast>
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
          <HiCheck className="h-5 w-5" />
        </div>
        <div className="ml-3 text-sm font-normal">
          We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
          <a href="/data-privacy" className="text-blue-500 hover:text-blue-700 ml-2">Learn more</a>
        </div>
        <Toast.Toggle onDismiss={() => setShowToast(false)} />
      </Toast>
    </div>
  );
};

const ContextWrappedRoutes = () => (
  <Suspense fallback={<LoadingOverlay />}>
    <SessionLogsProvider>
      <BlogProvider>
        <ContextsProvider>
          <ToolsProvider>
            <WorkflowsProvider>
              <Routes>
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/data-privacy" element={<DataPrivacy />} />
                <Route path="/about" element={<AboutUsPage />} />
                <Route path="/dashboard" element={<Dashboard />}>
                  <Route path="usage-history" element={<UsageHistoryDashboard />} />
                  <Route path="overview" element={<Overview />} />
                  <Route path="tools/:toolName" element={<ToolTemplate />} />
                  <Route path="workflows" element={<Workflows />} />
                  <Route path="marketplace" element={<Marketplace />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="settings" element={<Settings />} />
                </Route>
                <Route path="/logout" element={<Logout />} />
                <Route path="/blog/*" element={<BlogRoutes />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </WorkflowsProvider>
          </ToolsProvider>
        </ContextsProvider>
      </BlogProvider>
    </SessionLogsProvider>
  </Suspense>
);

function App() {
  return (
    <Router>
      <Flowbite theme={customTheme}>
        <FeedbackWrapper>
          <div className="flex flex-col min-h-screen w-full">
            <Header />
            <div className="flex-grow mt-16 w-full">
              <Suspense fallback={<LoadingOverlay />}>
                <Routes>
                  <Route path="/" element={<MainContent />} />
                  <Route path="/*" element={<ContextWrappedRoutes />} />
                </Routes>
              </Suspense>
            </div>
            <Footer />
          </div>
          <CookieNotification />
        </FeedbackWrapper>
      </Flowbite>
    </Router>
  );
}

export default App;